<template>
  <div class="home">
    <div class="media-wrap">
      <template v-if="isMobile ? [2].includes(currentPage) : (currentPage === 0)">
        <img src="../assets/images/pc/tg-p.png" alt="" @click="openTg('https://t.me/CatSxWarChat/1')">
        <img src="../assets/images/pc/x-p.png" alt="" @click="openTg('https://x.com/CatsXWar')">
      </template>
      <template v-else>
        <img src="../assets/images/pc/tg-w.png" alt="" @click="openTg('https://t.me/CatSxWarChat/1')">
        <img src="../assets/images/pc/x-w.png" alt="" @click="openTg('https://x.com/CatsXWar')">
      </template>
    </div>
    <div class="page-index">
      <div v-for="item in 4" :key="item" :class="{ 'active': item === (currentPage + 1) }" class="page-item" @click="goPage(item)"></div>
    </div>
    <nav class="mobile-nav">
      <img class="menu" src="../assets/images/menu.png" alt="menu" @click="showSide">

      <div class="menu-wrap">
        <img src="../assets/images/logo.png" alt="logo">
        <div class="menu-item active">Home</div>
        <div class="menu-item disabled">Road Map</div>
        <div class="menu-item disabled">White Paper</div>
      </div>

      <!-- @click="openTg('https://t.me/CatsXWarBot')" -->
      <div class="tg-btn" @click="openTg('https://t.me/CatsXWarBot')">
        Play for free
      </div>
    </nav>
    <div ref="fullpage" id="fullpage" name="fullpage" :accuracy="20" :startAt="0" :duration="500" class="full-page">
      <section class="section sec sec-1">
        <img src="../assets/images/logo.png" alt="logo">
        <div class="title">
          UNLEASH,<br/>
          PLAY,EARN
        </div>

        <div class="number-wrap">
          <div class="number-item">
            <div class="number">
              <span>
                <count-to :startVal="0" :endVal="data.robot || 0" />
              </span>

              <span>
                <!-- <count-to :startVal="0" :endVal="0" /> -->
                <span style="font-size: 32px;">Coming Soon</span>
              </span>
            </div>
            <div class="label">
              <span>
                Robot User
              </span>
              <span>
                Daily Users
              </span>
            </div>
          </div>

          <div class="number-item">
            <div class="number">
              <span>
                <count-to :startVal="0" :endVal="data.community || 0" />
              </span>

              <!-- <span>
                8.5M
              </span> -->
            </div>
            <div class="label">
              <span>
                Community <br />User
              </span>
              <!-- <span>
                Onchain <br/>Transaction
              </span> -->
            </div>
          </div>
        </div>

        <!-- <div class="tg-btn" @click="openTg">
          <svg t="1716956295154" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1831" width="128" height="128"><path d="M1020.521813 161.706667L866.495147 889.173333c-10.666667 51.626667-41.813333 64-85.333334 40.106667l-234.666666-173.653333-113.493334 109.653333c-12.8 12.8-23.466667 23.893333-46.933333 23.893333-30.72 0-25.6-11.52-35.84-40.533333L269.161813 584.533333 36.62848 512c-50.346667-14.933333-50.773333-49.493333 11.093333-74.666667l907.093334-349.866666c41.386667-18.346667 81.066667 10.24 65.28 73.813333z" p-id="1832" fill="currentColor"></path></svg>

          Telegram
        </div> -->

        <div class="cat-1"></div>
        <div class="cat-2"></div>
      </section>
      <section class="section sec sec-2">
        <div :class="{ 'show': isPlay }" class="video" ref="video"></div>

        <div v-if="!isPlay" class="play-btn" @click="play">
          <img src="../assets/images/video.png" alt="btn">
          <span>
            View trailer
          </span>
        </div>
      </section>
      <section class="section sec sec-3">
        <div class="wrap">
          <img src="../assets/images/logo.png" alt="logo">

          <div class="title">
            Telegram Mini Game
          </div>

          <div class="intro">
            Where every game leads to an airdrop adventure<br/>
            Play and trade using TG Game Bot
          </div>

          <!-- <div class="btn" @click="openTg">
            Explore game
          </div> -->
        </div>

        <div class="inner-fullpage">
          <div ref="f-body" class="inner-fullpage-body">
            <div ref="f-child" class="line">
              <div class="img-1">
                <div class="img-title">
                  Catzien
                </div>
                <div class="img-intro">
                  -catzienbot
                </div>
              </div>
              <div class="img-2">
                <div class="img-title">
                  Catzien
                </div>
                <div class="img-intro">
                  -catzienbot
                </div>
              </div>
            </div>

            <div class="column line">
              <div class="second-title">
                Play-ti-Airdrop
              </div>

              <img src="../assets/images/overview.png" alt="overview" class="overview-img">
            </div>

            <div class="three line">
              <img src="../assets/images/img-1.png" alt="img">
              <img src="../assets/images/img-2.png" alt="img">
              <img src="../assets/images/img-3.png" alt="img">
              <img src="../assets/images/img-4.png" alt="img">
              <img src="../assets/images/img-5.png" alt="img">
              <img src="../assets/images/img-6.png" alt="img">
            </div>
          </div>

          <div class="page">
            <img :class="{ 'disabled': currentNumber === 1 }" src="../assets/images/arrow.png" alt="arrow" @click="move(-1)">
            <span class="page-number">{{ currentNumber }}</span>
            <img :class="{ 'disabled': currentNumber === 3 }" src="../assets/images/arrow.png" alt="arrow" @click="move(1)">
          </div>
        </div>
      </section>
      <section class="section sec sec-4">
        <div class="title">
          CLASSIFICATION
        </div>

        <div class="item">
          <img src="../assets/images/type_1.png" alt="type">

          <div class="text">
            Single Cats Cause high damage to a single monster and scene
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_4.png" alt="type">

          <div class="text">
            Melee Cats Attack multiple enemy monsters in range at the same time
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_3.png" alt="type">

          <div class="text">
            Slow Cats Greatly slow down the monster's movement speed
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_2.png" alt="type">

          <div class="text">
            Range Cats Cause damage to all monsters and scenes around the target
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_5.png" alt="type">

          <div class="text">
            Toxic Cats Poison that causes continuous damage to the monster
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_6.png" alt="type">

          <div class="text">
            Penetrate Cats attack all targets in a straight line
          </div>
        </div>

        <div class="item">
          <img src="../assets/images/type_7.png" alt="type">

          <div class="text">
            Money Cats You can obtain gold coins when attacking monsters
          </div>
        </div>
      </section>
    </div>

    <transition name="aside">
      <aside v-show="isShowSide" class="side">
        <div class="aside-content">
          <div class="flex">
            <img src="../assets/images/logo.png" class="logo" alt="logo">
            <img src="../assets/images/close.png" class="close" alt="close" @click="isShowSide = false">
          </div>

          <div class="nav-item active">
            Home
          </div>
          <div class="nav-item disabled">
            Road Map
          </div>
          <div class="nav-item disabled">
            White Paper
          </div>
        </div>
      </aside>
    </transition>

    <transition name="aside-mask">
      <aside v-show="isShowSide" class="side-mask" @click="isShowSide = false"></aside>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import DPlayer from 'dplayer'
import video from '../assets/catswar.mp4'
import FullpageJs from 'fullpage.js'
import countTo from 'vue-count-to'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    countTo
  },
  data () {
    return {
      video: null,
      currentNumber: 1,
      isShowSide: false,
      isPlay: false,
      currentPage: 0,
      fullPage: null,
      isMobile: false,
      data: {
        community: 0,
        robot: 0
      }
    }
  },
  mounted () {
    this.isMobile = window.innerWidth < 500
    this.initVideo()
    let _ = this
    this.getData()
    this.fullPage = new FullpageJs('#fullpage', {
      //options here
      credits: {
        enabled: false,
        label: 'Cats X War'
      },
      onLeave: function (origin, destination, direction, trigger) {
        console.log(destination.index)
        _.currentPage = destination.index
        // //留下第二个section的第一个slide
        // if(section.index == 1 && origin.index == 0 && direction == 'right'){
        //   alert("离开第一个slide！");
        // }

        // //将第二个section的第三个slide留在左边
        // if(section.index == 1 && origin.index == 2 && direction == 'left'){
        //   alert("前往第二个slide！");
        // }
      }
    });
  },
  methods: {
    getData () {
      axios.get('https://catsxwar.com/api/users').then(res => {
        this.data = res.data.data || {
          robot: 0,
          community: 0
        }
      })
    },
    showSide () {
      this.isShowSide = true
    },
    openTg (url) {
      window.open(url)
    },
    initVideo () {
      const options = {
        container: this.$refs.video,
        video: {
          url: video
        },
        lang: 'en'
      }
      const dp = new DPlayer(options)

      this.video = dp
    },
    play () {
      if (!this.video) {
        return
      }
      if (!this.video.paused) {
        this.video.pause()
        this.isPlay = false
      } else {
        this.video.play()
        this.isPlay = true
      }
    },
    move (index) {
      if (this.currentNumber + index <= 0) {
        return
      } else if (this.currentNumber + index > 3) {
        return
      }
      this.currentNumber = this.currentNumber + index

      const body = this.$refs['f-body']
      const child = this.$refs['f-child']
      const width = child.offsetWidth

      body.style.transform = `translateX(${(this.currentNumber - 1) * -width}px)`
    },
    goPage (index) {
      this.fullPage.moveTo(index)
    }
  }
}
</script>

<style lang="scss">
  .dplayer-icon svg {
    width: 100%!important;
    height: 100%!important;
    color: #fff!important;
    path {
      fill: currentColor!important
    }
  }

  .fp-watermark {
    display: none!important;
  }

  .disabled {
    cursor: not-allowed!important;
    opacity: .4;
  }

  .fp-overflow {
    outline: none!important;
    box-shadow: none!important;
  }
</style>

<style lang="scss">
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .page-index {
    position: fixed;
    z-index: 9;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (min-width: 500px) {
      right: 24px;
    }
    .page-item {
      width: 8px;
      height: 24px;
      cursor: pointer;
      background-size: 100% 100%;
      background-image: url('../assets/images/index-other.png');

      &.active {
        background-image: url('../assets/images/index-current.png');
      }
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }
  }
  .media-wrap {
    position: fixed;
    right: 12px;
    top: 84px;
    display: flex;
    flex-direction: column;
    z-index: 9;
    @media screen and (min-width: 500px) {
      right: 24px;
    }
    img {
      cursor: pointer;
      margin-bottom: 16px;
      width: 28px;
      height: auto;
    }
  }
  .menu-wrap {
    display: none;
  }
  .home {
    @media screen and (min-width: 500px) {
      .mobile-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tg-btn {
          font-size: 18px;
          margin-top: 0;
          height: 48px;
          padding-bottom: 8px;
          box-sizing: border-box;
          width: 180px;
          padding-top: 0;
          line-height: 40px;
          cursor: pointer;
          display: flex;
        }
        .menu-wrap {
          display: flex;
          align-items: center;
          img {
            height: 48px;
            margin-right: 48px;
          }
          .menu-item {
            cursor: pointer;
            font-size: 20px;
            font-weight: bold;
            margin-right: 48px;
            color: #9d9aa6;
            &.active {
              color: #ae5df6;
            }
          }
        }
      }
      .menu {
        display: none;
      }
      .full-page {
        .sec {
          padding-left: calc((100vw - 1200px) / 2);
          padding-right: calc((100vw - 1200px) / 2);
          padding-top: 0;
          padding-bottom: 0;
        }
        .sec-1 {
          background-image: url('../assets/images/pc/bg-1.jpg');
        }
        .sec-2 {
          background-image: url('../assets/images/pc/bg-2.jpg');
        }
        .sec-3 {
          background-image: url('../assets/images/pc/bg-3.jpg');
        }
        .sec-4 {
          background-image: url('../assets/images/pc/bg-4.jpg');
        }

        .sec-1 {
          justify-content: center;
          .cat-1 {
            width: 240px;
            top: unset;
            bottom: 0;
            height: 500px;
            background-size: 100% auto;
          }
          .cat-2 {
            width: 600px;
            height: 640px;
            transform: rotateY(180deg);
            left: unset;
            right: 24px;
            bottom: 24px;
          }
          img {
            width: 420px;
          }
          .number-wrap {
            display: flex;
          }
          .number-item {
            display: flex;
            flex-direction: column;
            min-width: 600px;
            br {
              display: none;
            }

            .number {
              font-size: 48px;
            }
            .label {
              font-size: 24px;
            }
          }

          .tg-btn {
            font-size: 20px;
            padding: 12px 16px 20px;
            width: 180px;
            cursor: pointer;
          }
        }

        .sec-1 .title {
          font-size: 64px;
          margin-top: 48px;
          margin-bottom: 32px;
          br {
            display: none;
          }
        }

        .sec-2 {
          padding-left: 0;
          padding-right: 0;
          .video {
            opacity: 0;
            transition: all .2s;
            width: auto;
            height: 100%;
            &.show {
              opacity: 1;
            }
          }
        }

        .sec-3 {
          padding-left: 64px;
          padding-right: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .fp-overflow {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          img {
            width: 260px;
          }
          .title {
            margin-bottom: 24px;
            font-size: 40px;
          }
          .intro {
            font-size: 24px;
            line-height: 32px;
          }
          .btn {
            height: 64px;
            margin-top: 32px;
            width: 240px;
            font-size: 24px;
            background-size: 100% 100%;
          }
          .wrap {
            flex: 1;
          }
          .inner-fullpage {
            width: 40vw;
            position: unset;
          }
          .page {
            margin-top: 48px;
          }
          .inner-fullpage-body {
            .line {
              width: 40vw;
              min-width: 40vw;
            }
            .overview-img {
              height: 320px;
              width: auto;
            }
            .img-1, .img-2 {
              width: 300px;
              height: 553px;
            }
            .three {
              img {
                width: calc(20vw - 24px);
              }
            }
            .second-title {
              font-size: 30px;
            }
          }
        }

        .sec-4 {
          padding-top: 100px;
          padding-bottom: 32px;
          .fp-overflow {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 24px;
            flex-wrap: wrap;
          }
          .title {
            width: 100%;
            font-size: 48px;
            padding-top: 32px;
          }
          .item {
            width: calc(50% - 48px);
            margin: 0;
            padding-bottom: 32px;
            img {
              width: 80px;
            }
            .text {
              font-size: 20px;
              line-height: 32px;
            }
          }
        }
      }
    }
    .sec {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      padding-top: 60px;
    }
    .sec-1 {
      background-image: url('../assets/images/bg-1.jpg');
      background-size: cover;
      display: flex;
      flex-direction: column;
      padding-top: 160px;
      padding-left: 100px;
      padding-right: 100px;
      position: relative;
      .cat-1 {
        position: absolute;
        width: 80px;
        height: 200px;
        left: 0;
        top: 230px;
        background-image: url('../assets/images/cat-1.png');
        background-size: 80px auto;
        background-repeat: no-repeat;
      }
      .cat-2 {
        position: absolute;
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
        width: 240px;
        height: 249px;
        background-image: url('../assets/images/cat-2.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      img {
        width: 180px;
      }
      .title {
        font-size: 32px;
        font-weight: 900;
        color: #fe5e52;
        text-shadow: 3px 4px 1px rgba($color: #fe5e52, $alpha: 0.24);
        margin: 20px 0;
      }

      .number, .label {
        display: flex;
        >* {
          flex: 1;
        }
      }

      .number {
        font-size: 24px;
        color: #05123e;
        font-weight: bold;
      }

      .label {
        margin-top: 4px;
        margin-bottom: 12px;
        font-size: 14px;
        color: #05123e;
      }
    }
    .sec-2 {
      background-image: url('../assets/images/bg-2.jpg');
      background-size: cover;
      display: flex;
      justify-content: center;
      padding-top: 240px;
      position: relative;

      .play-btn {
        position: absolute;
        bottom: 32px;
        padding-bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 240px;
        height: 47px;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        background-image: url('../assets/images/btn-bg.png');
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
        padding-bottom: 4px;
        background-size: 100% auto;
        cursor: pointer;

        img {
          width: 40px;
          margin-right: 12px;
        }
      }

      .video {
        width: 100vw;
        height: 56.25vw;
        position: relative;
      }
    }
    .sec-3 {
      background-image: url('../assets/images/bg-3.jpg');
      background-size: cover;
      padding: 100px 24px 32px;
      position: relative;

      img {
        width: 180px;
      }

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #fe5e52;
        margin: 24px 0 12px;
        font-style: italic;
      }

      .intro {
        font-size: 14px;
        color: #9d9aa6;
        font-weight: bold;
        font-style: italic;
        line-height: 20px;
      }

      .btn {
        margin-top: 16px;
        height: 48px;
        padding-bottom: 8px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        background-image: url('../assets/images/btn-bg.png');
        background-size: 180px 48px;
        color: #fff;
        font-size: 20px;
        font-style: italic;
        display: inline-flex;
        width: 180px;
      }

      .inner-fullpage {
        position: absolute;
        bottom: 32px;
        left: 24px;
        width: calc(100vw - 48px);
        overflow: hidden;
      }

      .inner-fullpage-body {
        display: flex;
        flex-wrap: nowrap;
        transition: all .3s;

        .line {
          width: calc(100vw - 48px);
          min-width: calc(100vw - 48px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding-top: 64px;

          &.column {
            flex-direction: column;
          }
        }
      }

      .img-1, .img-2 {
        width: 180px;
        height: 332px;
        background-image: url('../assets/images/phone1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        box-sizing: border-box;
        padding-top: 4px;
        position: relative;
        .img-title {
          color: #fe5e52;
          font-size: 18px;
          font-weight: bold;
        }
        .img-intro {
          color: #9d9aa6;
          font-size: 14px;
        }
      }

      .img-1 {
        z-index: 1;
        transform: translate(32px, 24px);
      }
      .img-2 {
        z-index: 0;
        background-image: url('../assets/images/phone2.png');
        transform: translate(-24px, -64px);
      }

      .page {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 36px;
          &:first-of-type {
            transform: rotate(180deg);
          }
        }
        .page-number {
          color: #fff;
          font-size: 36px;
          margin: 0 16px;
          font-weight: bold;
          font-style: italic;
        }
      }

      .second-title {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        font-style: italic;
      }

      .overview-img {
        width: calc(100vw - 96px);
        margin-top: 32px;
      }

      .three {
        flex-wrap: wrap;
        gap: 12px;
        img {
          width: calc((100vw - 48px - 12px) / 2);
        }
      }
    }
    .sec-4 {
      background-image: url('../assets/images/bg-4.jpg');
      background-size: cover;
      padding-top: 100px;
      padding-bottom: 32px;

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        margin-bottom: 32px;
      }

      .item {
        background-image: url('../assets/images/split-line-2.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center bottom;
        padding-bottom: 8px;
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 12px;

        img {
          width: 48px;
        }

        .text {
          font-size: 14px;
          color: #fff;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .mobile-nav {
    height: 60px;
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: 100vw;
    z-index: 9;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    align-items: center;

    .tg-btn {
      // display: none;
      margin-top: 0;
      margin-left: auto;
    }

    .menu {
      width: 24px;
    }
  }

  .side {
    background-color: #fff;
    width: calc(100vw - 80px);
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 11;
    box-sizing: border-box;
    padding: 48px 24px;

    .logo {
      height: 48px;
    }

    .close {
      height: 20px;
      transform: translate(4px, -24px);
    }

    .nav-item {
      padding: 16px 0;
      background-image: url('../assets/images/split-line-1.png');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100% auto;
      font-weight: bold;
      color: #818181;
      &.active {
        color: #ff7a33;
      }
    }
  }

  .side-mask {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
  }

  .aside-mask-enter-active, .aside-mask-leave-active {
    transition: opacity .3s;
  }
  .aside-mask-enter, .aside-mask-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  .aside-enter-active, .aside-leave-active {
    transition: all .4s;
  }
  .aside-enter, .aside-leave-to /* .fade-leave-active in <2.1.8 */ {
    transform: translateX(-100%);
  }

  .tg-btn {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    padding: 6px 16px 10px;
    line-height: 20px;
    background-image: url('../assets/images/btn-bg.png');
    background-size: 100% 100%;
    margin-top: 24px;
    width: 110px;
    justify-content: center;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
</style>
