<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    window.telegramAnalytics.init({
      token: 'eyJhcHBfbmFtZSI6IkNhdHNYV2FyIiwiYXBwX3VybCI6Imh0dHBzOi8vY2F0c3h3YXIuY29tIiwiYXBwX2RvbWFpbiI6Imh0dHBzOi8vY2F0c3h3YXIuY29tIn0=!O/DLRtUxmCt0tqOUc34TdggdbqKouSwVHlSy5iI0U/A=',
      appName: 'CatsXWar',
    });
  }
}
</script>

<style lang="scss">
</style>
